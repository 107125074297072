.event-detail {

  #header-wrapper {
      display: none;
  }

  .back-to-section {
    text-align: center;
    a {
      vertical-align: middle;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: #fff;;
    }
    span {
      vertical-align: middle;
    }
  }

  .page {
    background: #fff;
    overflow: hidden;

    .data-error{
      font-size: 20px;
      text-align: center;
      padding: 30px;
      color: $blue;
    }
  }

  .event-header {
    padding: 0;
    margin-bottom: 30px;

    .title-wrapper {
      position: relative;
      overflow:visible;

      .flag-overlay {
          position:absolute;
          top: -40px;
          left: -20px;
          transform:rotate(10deg);
          font-size: 220px;
          opacity: 0.05;
          line-height: 0;
          z-index: 0;
      }

    }
    .logo-wrapper {
      text-align: center;
      @include media-breakpoint-up(sm) {
        text-align:right;
      }

      img {
        display: inline-block;
        @include media-breakpoint-up(sm) {
          max-width: 150px;
        }
      }
    }
    .event-title {
      position: relative;
      color: theme-color('primary');
      font-weight: 900;
      font-size: 24px;
      z-index: 1;
      @include media-breakpoint-up(sm) {
        font-size: 32px;
      }
    }
    .event-details {
      li {
        vertical-align: middle;
        .icon {
          display: inline-block;
          vertical-align: middle;
          margin-right: 5px;
        }
      }
    }
    .location {
      font-size: 16px;
      z-index: 1;
      @include media-breakpoint-up(sm) {
        font-size:  20px;
      }
    }
    .start-date {
      font-size: 16px;
      z-index: 1;
      @include media-breakpoint-up(sm) {
        font-size:  20px;
      }
    }


  }

  .nav.nav-pills {
    font-size: 15px;
    text-transform: uppercase;
    border-radius: 0;
    background-color: $light-blue;

    .nav-item {
      border-radius: 0;

      .nav-link.active {
        background-color: $blue;
        border-radius: 0;
      }
    }
  }

  .event-content {

    @include media-breakpoint-down(sm) {
      padding-left: 0;
      padding-right: 0;
    }

    .table-responsive{
      @include media-breakpoint-down(sm) {
        &:before{
          content: 'Swipe to the right to view the complete content.';
          color: #002e4f;
          font-size: 11px;
          margin-bottom: -10px;
          padding-top: 10px;
        }
      }
    }
  }


  // alpine-skiing
  .alpine-skiing & {
    .event-additionnal-info{
      display: none;
    }
    .no-res-available{
      padding-bottom: 40px;
    }
    .defending-winner{
      margin: 0 auto;
      margin-top: 50px;
      padding-top: 0px;

      p {
        margin: 0;
        padding: 0;
      }
      .flag-icon,
      .fi {
        position: absolute;
        z-index: 0;
        transform: scale(5) rotate(10deg);
        opacity: 0.1;
        left: 50%;
        top: 80%;
        overflow: hidden;
      }
    }
    .event-content {
      .results, .rankings {
        .table-results,
        .table-rankings,
        .table {
          th,
          td {
            padding: 8px 3px;
          }
          th {
            line-height: 15px;

            &.rank,
            &.bib,
            &.time,
            &.wc-points {
              text-align: center;
            }
          }
          td {
            position: relative;
            overflow: hidden;

            &.rank,
            &.bib,
            &.time,
            &.wc-points {
              text-align: center;
            }

            a {
              margin: 0!important;
            }

            &.rank-1 {
              span {
                padding: 3px 8px;
                color: #fff;
                font-weight: 900;
                background-color: #fde3c3;
              }
            }
            &.rank-2 {
              span {
                padding: 3px 8px;
                color: #fff;
                font-weight: 900;
                background-color: #d0d4dc;
              }
            }
            &.rank-3 {
              span {
                padding: 3px 8px;
                color: #fff;
                font-weight: 900;
                background-color: #cdbca6;
              }
            }
          }
          .flag-icon,
          .fi {
            position: absolute;
            z-index: 0;
            transform: scale(5) rotate(10deg);
            opacity: 0.1;
            left: 50px;
            top: 50%;
          }
        }
        .table-results,
        .table-rankings {

          .rank {
            width: 30px;
            @include media-breakpoint-up(sm) {
              width: 49px;
            }
            @include media-breakpoint-up(md) {
              width: 69px;
            }
            @include media-breakpoint-up(lg) {
              width: 115px;
            }
          }
          .bib {
            width: 31px;
            @include media-breakpoint-up(sm) {
              width: 34px;
            }
            @include media-breakpoint-up(md) {
              width: 48px;
            }
            @include media-breakpoint-up(lg) {
              width: 80px;
            }
          }

          th:nth-child(5),
          td:nth-child(5) {
            display: none;
          }
        }

        .other-results {
          .rank {
            width: 30px;
            @include media-breakpoint-up(sm) {
              width: 49px;
            }
            @include media-breakpoint-up(md) {
              width: 69px;
            }
            @include media-breakpoint-up(lg) {
              width: 115px;
            }
          }
          .bib {
            width: 31px;
            @include media-breakpoint-up(sm) {
              width: 34px;
            }
            @include media-breakpoint-up(md) {
              width: 48px;
            }
            @include media-breakpoint-up(lg) {
              width: 80px;
            }
          }
        }
        .event-additionnal-info {
          padding: 30px 0;
          .table {
            @extend .table-borderless;
            th,
            td {
              text-align: left;
              padding: 2px;
            }
          }
        }

        .downloads {
          margin-top: 30px;

          a {
            display: inline-block;
            border: 1px solid #dee2e6;
            background: #dee2e6;
            color: theme-color('primary');
            padding: 5px 10px;
            margin-right: 5px;
            margin-bottom: 5px;
            transition: all .3s ease;
            &:hover {
              background: theme-color('primary');
              color: #fff;
            }
          }
        }
      }

      .podium {
        max-width: 600px;
        margin: 0 auto 60px;

        h3 {
          text-align: center;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-size: 24px;
          margin-bottom: 30px;
        }

        .row {
          @extend .no-gutters;
        }

        .podium-1,
        .podium-2,
        .podium-3 {
          @extend .col-4;

          position: relative;
          padding-right: 0;
          padding-left: 0;
          text-align: center;
          font-size: 15px;

          @include media-breakpoint-up(sm) {
            font-size: 16px;
          }

          .inner {
            position: relative;
            background: #fff;
            box-shadow: 0px 10px 15px rgba(0,0,0,.12);
            padding: 5px;
            overflow: hidden;
            padding: 10px;

            @include media-breakpoint-up(sm) {
              padding: 30px;
            }

            .podium-header {
              position: relative;
              text-align: center;
              z-index: 1;
              a {
                display: block;
                background-repeat: no-repeat;
                background-position: center top;
                height: 50px!important;
                background-size: auto 50px;

                @include media-breakpoint-up(sm) {
                  height: 80px!important;
                  background-size: auto 80px;
                }
              }

              .podium-rank {
                display: inline-block;
                margin: 5px auto;
                padding: 3px 8px;
                background-color: #fde3c3;
                color: #fff;
                font-weight: 900;
              }
            }
            .podium-content {

              .podium-name {
                font-size: 15px;
                position: relative;

                @include media-breakpoint-up(sm) {
                  font-size: 20px;
                }
                p {
                  margin-bottom: 0;
                }
              }
              .country {
                display: none;
              }
              .podium-time {
                font-size: 15px;
                position: relative;

                @include media-breakpoint-up(sm) {
                  font-size: 24px;
                }
              }
            }

            .flag-icon,
            .fi {
              position: absolute;
              bottom: -30%;
              left:50%;
              transform: rotate(10deg) translateX(-50%);
              font-size: 102px;
              opacity: 0.05;
              z-index: 0;
              transition: all 1s ease-out;

              @include media-breakpoint-up(sm) {
                font-size: 220px;
                bottom: -50%;
              }
            }


          }

        }
        .podium-1 {
          z-index:2;
          transform:scale(1.1);
        }
        .podium-2 {
          .inner {
            .podium-header {
              .podium-rank {
                  background-color: #d0d4dc;
              }
            }
          }
        }
        .podium-3 {
          .inner {
            .podium-header {
              .podium-rank {
                  background-color: #cdbca6;
              }
            }
          }
        }
      }
    }
    // .event-sidebar {
    //   > div {
    //     margin-top: 60px;
    //     > div:first-child {
    //       padding: 5px;
    //       text-transform: uppercase;
    //       font-size: 20px;
    //       line-height: 20px;
    //       background: $blue;
    //       color: #ffffff;
    //     }
    //   }
    //   a {
    //     display: block;
    //     padding: 5px;
    //     border: 1px solid $light-blue;
    //     background: transparent;
    //     transition: all .3s ease-out;
    //     margin-bottom: 5px;
    //
    //     &:hover,
    //     &:focus {
    //       background: $blue;
    //       color: #fff;
    //     }
    //   }
    // }
  }

  // equestrian
  .equestrian & {
    .event-content {


      // Fix grid equestrian
      [class*="col-"]  {
        flex:none;
        max-width: none;
      }

      h3.hd_h3 {
        background-color: $blue!important;
      }
      .hd_cl_picto {
        img {
        //  max-width: 20px;
        }

        // Fix for tablet device
        @media (max-width: 991px) and (min-width: 768px)  {
          display: none;
        }
      }
      .event-title.blue.hd_h3{
        font-weight: 400!important;
        background-color: $blue!important;
      }
      h4.hd_spacer {
        font-size: 18px!important;;
        padding-bottom:5px!important;
        color: #9ea4b4!important;
        border-bottom: 2px solid #dee2e6!important;
      }
      .hd_cl_content {
        margin-bottom: 8px;
      }
      .hd_cl_line {
        position: relative;
        border-bottom: 1px solid #dee2e6!important;
        padding: 10px 0;
        margin-top: 15px;
        margin-bottom: 15px;
        &:after {
          content:'';
          position: absolute;
          display: block;
          width: 100%;
          height: 5px;
          bottom:0;
          left: 0;
          background: white;
          z-index: 2;
        }
        &:before {
          content:'';
          position: absolute;
          display: block;
          width: 100%;
          height: 5px;
          top: 15px;
          left: 0;
          background: white;
          z-index: 2;
        }
      }
      div.hd_cl_sponsor {
        color: $blue!important;
      }
      .hd_header {
        margin: 20px 0;
      }
      th.hd {
        background: $blue!important;
      }

      table{
        td{
          &.hd_ridername{
            position: relative;
            a {
              position: relative;
              z-index: 1;
            }
            .flag-icon,
            .fi {
              position: absolute;
              z-index: 0;
              transform: scale(4.5) rotate(10deg);
              opacity: 0.1;
              left: 30px;
              top: 80%;

              &.flag-icon-tw,
              &.fi-tw {
                  background-image: url(../imgs/icons/tw.svg);
              }
            }
          }

          .hd_cl_line {


          }
        }
      }

    }
  }

  // gymnastics
  .gymnastics & {

    .live-timing-link {
      display: block;
      border: 2px solid #d33f3c;
      text-align: center;
      background: rgba(255, 255, 255, .7);
      text-transform: uppercase;
      padding: 6px 0;
      font-weight: 600;
      margin-top: 20px;
      color: #d33f3c;
      margin-bottom: 30px;
    }
    .event-box {
      background: #f1f1f4;
      padding: 30px;
      margin-bottom: 30px;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }
  }

}
.equestrian.lite {
  #offcanvas { display: none;}
  .banner {
    .nav-toggle { display: none;}
    .sport-navigation{ display: none }
  }
  .event-detail {
    & > header { display: none; }
  }
  footer.footer { display: none; }
}

#ajax-modal {
    .modal-xl {
        max-width: 1070px;
        padding-left: 10px;
        padding-right: 0px;

        @include media-breakpoint-down(lg) {
            max-width: 940px;
            padding-left: 15px;
            padding-right: 15px;
        }

        @include media-breakpoint-down(md) {
            max-width: 700px;
        }
        
    }
    .modal-header {
        padding-bottom: 0;
        border-bottom: none;
    }
    .modal-body {
        padding: 30px;
        padding-top: 0px;
    }
}
