.footer {
  padding: 30px 0;

  .copyright {
    display: block;
    margin-bottom: 15px;
    color: rgba(#fff,0.5);
    font-size: 12px;
    text-align: center;

    span {
      display: block;
    }


    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
      display: flex;
      font-size: 15px;
      text-align: left;

      span {
        display: inline;
      }
    }
  }
  .footer-nav {
    font-size: 16px;
    text-transform: uppercase;

    justify-content: center;

    @include media-breakpoint-up(sm) {
      justify-content: flex-end;
    }

    a {
      padding: 5px;
      color: rgba(#fff,0.7);
      &:hover,
      &:focus {
        color: #fff;
      }
    }
  }
}
