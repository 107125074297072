.banner {
    padding: 30px 15px;
    @include media-breakpoint-up(sm) {
      padding: 30px;
    }

    .navbar {
      min-height: 46px;
      padding: 0;
      z-index: 1003;
    }

    .navbar-nav {
        text-transform: uppercase;
        font-size: 20px;

          .nav-link {
            padding-left: 10px;
            padding-right: 10px;

            &.active {
              background-color: transparent;
            }
          }
    }
    .sport-navigation {
      @include media-breakpoint-down(md) {
        display: none;
      }

      > li {
        a {
          position: relative;
          &:after {
            content:'';
            width: 0;
            height: 1px;
            background: #fff;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-25px);
            transform-origin: 0% 0%;
            transition: all .3s ease-out;
          }

          .new-front &[href="/gymnastics"] {
            display: none;
          }

          &.active,
          &:hover {
            &:after {
              width: 40px;
            }
          }
        }
      }

      .homepage & {
        display: none;
      }
    }
    .navbar-brand {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        display: inline-block;
        background-color: transparent!important;
        background-image : url(../imgs/longines-timing-logo.svg);
        text-indent: -9999px;
        width: 170px;
        height: 50px;
        background-size: 170px 50px;
        z-index: 100;
    }

    .user-navigation {
        display: none!important;
        font-size: 36px;
    }
}


// Burger Menu
.nav-toggle {
  position: relative;
  cursor: pointer;
  text-decoration: none;
  background:transparent;
  z-index: 21;
  padding: 0;
  border: 0 none;
  display: none;
  height: 44px;
  width: 44px;
  border-bottom: none!important;
  top: -12px;

  @include media-breakpoint-down(md) {
  	display: block;
  }

  &.active i {
      &::before, &::after {
          background: #fff;
      }
  }
  &:hover, &:focus, &:active {
  	outline: none;
  	border-bottom: none!important;
  }
  i {
  	  position: relative;
  	  display: inline-block;
  	  width: 25px;
  	  height: 3px;
  	  color: #fff;
  	  text-transform: uppercase;
  	  text-indent: -55px;
  	  background: #fff;
  	  transition: all .2s ease-out;
  		&::before,
      &::after {
	  	  content:'';
  		  width: 25px;
  		  height: 3px;
  		  background: #fff;
  		  position: absolute;
  		  left:0;
  		  transition:all .2s ease-out;
	    }
  }

  i::before {
    top: -7px;
  }
  i::after {
    bottom: -7px;
  }
  &.active i {
      background-color: transparent!important;

      &::before {
        top:0;
        transform: rotateZ(45deg);
      }
      &::after {
        bottom:0;
        transform: rotateZ(-45deg);
      }
  }

}
