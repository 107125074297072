body {
  position: relative;
  font-weight: 400;
  background-position: 0;
  background-size: 100% auto;
  background-repeat: no-repeat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.no-scoll {
    overflow: hidden;
  }
}

h1, h2, h3, h4, h5 {
  letter-spacing: -1px;
}

.app-longines-timing {
   position: relative;
   width: 100%;
   min-height: 100vh;
   background-size: 100% auto;
   background-position: top center;
   background-repeat: no-repeat;
   background-image: url(../imgs/longines-timing-header-mobile.jpg);
   overflow: hidden;

   @include media-breakpoint-up(md) {
     background-image: url(../imgs/longines-timing-header.jpg);
   }

   .new-front & {
      background-image: url(../imgs/longines-timing-header-mobile-new.jpg);

      @include media-breakpoint-up(md) {
        background-image: url(../imgs/longines-timing-header-new.jpg);
      }
    }

    &.alpine-skiing {
        background-image: url(../imgs/alpine-skiing-header-mobile.jpg);
        @include media-breakpoint-up(md) {
          background-image: url(../imgs/alpine-skiing-header.jpg);
        }
    }
    &.equestrian {
        background-image: url(../imgs/equestrian-header-mobile.jpg);
        @include media-breakpoint-up(md) {
          background-image: url(../imgs/equestrian-header.jpg);
        }

        .row{
          &:before,
          &:after{
            display: none;
          }
        }
    }
    &.gymnastics {
        background-image: url(../imgs/gymnastics-header-mobile.jpg);
        @include media-breakpoint-up(md) {
          background-image: url(../imgs/gymnastics-header.jpg);
        }
    }
    &.sport-history {
        background-image: url(../imgs/sport-history-header-mobile.jpg);
        @include media-breakpoint-up(md) {
          background-image: url(../imgs/sport-history-header.jpg);
        }
    }
    &.cwg {
        background-image: url(../imgs/cwg/longines-cwg-header-mobile.jpg);
        @include media-breakpoint-up(md) {
          background-image: url(../imgs/cwg/longines-cwg-header.jpg);
        }
    }

    #loader{
      position: fixed;
      z-index: 999999;
      background-color: #131a24;
      top:0;
      bottom:0;
      left:0;
      right: 0;
      width: 100%;
      height: 100vh;

      .loader-inner {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 75px;
        height: 16px;
        transform: translate(-50%,-50%);

        .loader-logo {
          mask-image: url(../imgs/hourglass.svg);
          mask-mode: alpha;
          mask-repeat: no-repeat;
          mask-size: 75px 16px;
          mask-position: center;
          width: 75px;
          height: 16px;

          .progress {
            height: 32px;
            background: transparent;
            border-radius: 0;
          }

          .progress-bar {
            height: 32px;
            border-radius: 0;
          	background-color: white;
            width: 100%;
            transition-property: all;
            transition-timing-function: ease-in-out;
            width: 0;
            animation: progressAnimationStrike 0.3s;
          }
        }
      }
      &.ie, &.edge{
          .loader-inner {
            background-image: url(../imgs/hourglass.png);
            background-size: contain;
            .loader-logo{
              .progress{
                display: none;
              }
              .progress-bar{
                display: none;
              }
            }
          }
      }
    }
}

@keyframes progressAnimationStrike {
     from { width: 0 }
     to   { width: 100% }
}

// fix for html data coming from Provider
.app-longines-timing {
  @media (max-width: 767px) {
    .hidden-xs{
      display: none;
    }
  }
  // SM
  @media (max-width: 991px) and (min-width: 768px) {
    .hidden-sm{
      display: none;
    }
  }
  @media (max-width: 1199px) and (min-width: 992px) {
    .hidden-md{
      display: none;
    }
  }
  @media (min-width: 1200px) {
    .hidden-lg{
      display: none;
    }
  }
}


a {
  color: theme-color('primary');
  transition: all .3s ease;

  &:hover,
  &:focus {
    text-decoration: none;
    color: darken(theme-color('primary'), 10%);
  }
}

.web-cis {
  ion-icon {
    font-size: 16px;
    line-height: 1.5;
    vertical-align: middle;
    margin-right: 3px;
  }
  span {
    vertical-align: middle;
  }
}

.btn-square {
  border-radius: 0;
}

.btn-medium {
  padding: 8px 16px;

  @include media-breakpoint-up(md) {
    padding: 12px 40px;
  }
}

.btn-big {
  padding: 20px 54px;
}

.big-title {
  font-size: 2.5rem;
}

.cwg-logo {
   display: inline-block;
   position: relative;
   z-index: 999;
   background-image: url(../imgs/cwg/birmingham-2022-logo-neg.svg);
   background-repeat: no-repeat;
   text-indent: -9999px;
   margin: 15px;
   margin-left: 55px;
   width: 140px;
   height: 140px;
   @include media-breakpoint-up(md) {
      width: 230px;
      height: 230px;
  }
}

a.square-link {
  top: 30px;
  right: 30px;
  border: 1px solid #fff;
  padding: 5px 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #ffffff;
  transition: all .3s ease;

  &:hover {
    background: #ffffff;
    color: theme-color('primary');
  }
}

.feed-wrapper{
  // .slick-track {
  //   display: flex !important;
  // }

  // .slick-slide {
  //   height: auto;

  //   > div {
  //     height: 90%;
  //   }
  // }

  .slick-dots {
    height: 28px;
    overflow: hidden;
    li {
      width: 10px;
    }
  }

  .slick-next,
  .slick-prev {
    &::before {
      color: theme-color('secondary');
      z-index: 9999;
      font-family: "Ionicons"
    }
  }
  .slick-next {
    transform: rotate(180deg);
    &::before {
      content: "";
    }
  }
  .slick-prev {
    &::before {
      content: "";
    }
  }
}

.dyn-banner-wrapper {
  overflow: hidden;
  height: 0;
  padding-bottom: percentage(300px / 1110px);

  @include media-breakpoint-up(sm) {
    height: auto;
    padding-bottom: 0;
  }

  .dyn-banner {
    width: 100%;
    height: 125px;

    @include media-breakpoint-up(sm) {
        height: 137px;
    }
    @include media-breakpoint-up(md) {
        height: 185px;
    }
    @include media-breakpoint-up(lg) {
        height: 251px;
    }
    @include media-breakpoint-up(xl) {
        height: 300px;
    }
}
}

.cta-overlay {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;
  background-color: theme-color('primary');
  opacity: 0.8;

  h2 {
    font-size: 31px;

    @include media-breakpoint-up(sm) {
        font-size: 61px;
    }
  }

}

.rgpd-disclaimer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: theme-color('primary');
  z-index: 99999;

  &__inner {
    padding: 15px;
  }

  &__message {
    font-size: 0.85rem;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  &__action {
    text-align: center;

    @include media-breakpoint-up(md) {
        text-align: right;
    }
  }
}
